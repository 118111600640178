var script = document.createElement('script');
script.src = 'https://api-web.lianhdt.com/action-captcha/action-captcha.min.js';
document.head.appendChild(script);
import Vue from 'vue'
import App from './App.vue'
import store from './store'

import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload'


import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);
// import JwtPlugin from './utils/jwtPlugin'

// Vue.use(JwtPlugin)

import axios from 'axios'

Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  // //失败时显示的图片
  // error: require('./assets/img/error.png'),
  //加载时显示的GIF图
  // loading: require('./assets/img/loading.gif'),
  //尝试加载几次
  attempt: 1
});
Vue.use(ElementUI);
Vue.config.productionTip = false
//设置超时时间
axios.defaults.timeout = 5000
//设置api地址
//全局定义axios
axios.defaults.baseURL = 'http://shkjgw.shkjem.com/api/'
Vue.prototype.$http = axios;
Vue.prototype.phoneReg = /^1[3-9]\d{9}$/;
// Vue.prototype.JumeiActionCaptcha = '';
Vue.prototype.CAPTCHA_APP_ID = '193229516';
// 图片服务器地址
Vue.prototype.imgserver = 'http://shkjgw.shkjem.com/';
Vue.prototype.uploadUrl = 'http://8.152.162.160:18080/tms-cloud-miniprogram-service/oss/fs/upload/image';
Vue.prototype.resourceUrl = 'http://8.152.162.160:18080/tms-cloud-miniprogram-service/oss/fs/resource/image';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
