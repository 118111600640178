/* global JumeiActionCaptcha */
<template>
    <div id="app">
        <el-container>
            <el-header style="display: flex;justify-content: space-between;padding-left: 100px">
                <div  @click="toHome" style="cursor: pointer;display: flex;flex-direction: column;justify-content: center;">
                    <img src="./assets/img/logo.png" alt="logo"/>
                </div>
                <!--        <el-menu-->
                <!--                :default-active="defaultActive"-->
                <!--                class="el-menu-demo"-->
                <!--                mode="horizontal"-->
                <!--                @select="handleSelect"-->
                <!--                :router="router"-->
                <!--                background-color="transparent"-->
                <!--        >-->
                <div >
                    <el-menu
                            :default-active="$route.path"
                            :router="router"
                            background-color="transparent"
                            active-text-color="transparent"
                            text-color="transparent"
                            class="el-menu-demo menu-container"
                            mode="horizontal"
                            @select="handleSelect"
                    >
                        <el-menu-item v-for="item in menuItems" :key="item.index" :index="item.index">
                            {{ item.label }}
                        </el-menu-item>
                        <!--<el-menu-item index="/">首页</el-menu-item>-->
                        <!--<el-menu-item index="/deliveryOrder">下单发货</el-menu-item>-->
                        <!--<el-menu-item index="/myOrderList">我的运单</el-menu-item>-->
                        <!--<el-menu-item index="/selfHelpLoan">自助放款</el-menu-item>-->
                        <!--<el-menu-item index="/changeInformation">修改信息</el-menu-item>-->
                        <!--<el-menu-item index="/paymentReconciliation">货款对账</el-menu-item>-->
                        <!--<el-menu-item index="/companyProfiles">企业简介</el-menu-item>-->
                        <el-submenu index="dropdown" v-if="showDropdown">
                            <template slot="title"><span style="font-size: 16px;color: white">更多</span></template>
                            <el-menu-item v-for="item in dropdownItems" :key="item.index" :index="item.index">
                                {{ item.label }}
                            </el-menu-item>
                        </el-submenu>
                    </el-menu>
                </div>
                <div >
                    <div v-if="userObj.loginPhone" style="line-height: 60px;">
                        <el-dropdown @command="logOut" trigger="click" @visible-change="handleDrop">
                          <span class="el-dropdown-link">
                            {{userObj.userName}}<i v-show="isChange == false" class="el-icon-arrow-down el-icon--right"></i>
                            <i v-show="isChange == true" class="el-icon-arrow-up el-icon--right"></i>
                          </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div v-else
                         style="width: 80px;text-align: center;margin: 15px;height: 30px;line-height: 30px;background: #0082f5;border-radius: 15px;cursor: pointer">
                        <div style="color: #FFFFFF" @click="showPromotionDialog()">登录</div>
                    </div>
                </div>

            </el-header>
            <el-main>
                <router-view/>
            </el-main>
            <PromotionDialog ref="promotionDialog"></PromotionDialog>
            <el-dialog
                    :visible.sync="dialogVisible"
                    width="20%"
                    :show-close="showClo"
                    style="margin-top: 150px"
            >
                <el-tabs v-model="activeName" @tab-click="handleClick" style="">
                    <el-tab-pane label="验证码登录" name="first" style="margin:20px;">
                        <el-input v-model="phone" placeholder="请输入手机号" style="width: 100%;margin:0 auto"></el-input>
                        <div style="display: flex;margin:10px 0;">
                            <el-input v-model="verification" placeholder="请输入验证码"
                                      style="margin-right: 10px;"></el-input>
                            <el-button type="primary" @click="open()" :disabled="countdown > 0"> {{ countdown > 0 ?
                                `${countdown}秒后重新获取` : '获取验证码' }}
                            </el-button>
                        </div>
                        <el-button type="primary" style="width: 100%;margin-top: 40px" @click="loginSendMobileCode">
                            立即登录
                        </el-button>
                    </el-tab-pane>

                    <el-tab-pane label="微信登录" name="second" style="text-align: center">
                        <div style="width: 230px;height: 230px;margin:0 auto">
                            <img v-lazy="imageSrc" style="width: 230px;height: 230px;"/>
                        </div>

                        <div style="color: #7E7E7E;font-size: 16px;padding: 15px">请打开微信扫一扫,快速登录</div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </el-container>
    </div>
</template>

<script>

    import {jwtDecode} from 'jwt-decode'
    import {login, getLoginImg, checkScan, loginScan, sendMobileCode, phoneLogin} from "./api/homeApi";
    import PromotionDialog from "@/components/PromotionDialog.vue";

    export default {
        components: {PromotionDialog},
        data() {
            return {
                isChange: false,
                verification: '',//登录验证码
                phone: '',//登录手机号
                showClo: false,
                activeName: 'first',
                dialogVisible: false,//登录弹窗
                router: false,
                defaultActive: "/",
                isShow: false,
                imageSrc: '',
                sceneId: '',
                intervalId: null,
                intervalIdSendMobileCode: null,//短信验证码倒计时
                countdown: 0,//短信验证码倒计时
                captcha: null,
                userObj: {
                    loginPhone: ''
                },
                showDropdown: false,
                menuItems:[
                    // {index: '/', label: '首页'},
                    // {index: '/deliveryOrder', label: '下单发货'},
                    // {index: '/myOrderList', label: '我的运单'},
                    // {index: '/selfHelpLoan', label: '自助放款'},
                    // {index: '/changeInformation', label: '修改信息'},
                    // {index: '/paymentReconciliation', label: '货款对账'},
                    // {index: '/companyProfiles', label: '企业简介'},
                ],
                menuItemsPc: [
                    {index: '/', label: '首页'},
                    {index: '/deliveryOrder', label: '下单发货'},
                    {index: '/myOrderList', label: '我的运单'},
                    {index: '/selfHelpLoan', label: '自助放款'},
                    {index: '/changeInformation', label: '修改信息'},
                    {index: '/paymentReconciliation', label: '货款对账'},
                    {index: '/companyProfiles', label: '企业简介'},
                ],
                menuItemsMobile:[
                    {index: '/', label: '首页'},
                    {index: '/deliveryOrder', label: '下单发货'},
                    {index: '/myOrderList', label: '我的运单'},
                ],
                dropdownItems: [
                    {index: '/selfHelpLoan', label: '自助放款'},
                    {index: '/changeInformation', label: '修改信息'},
                    {index: '/paymentReconciliation', label: '货款对账'},
                    {index: '/companyProfiles', label: '企业简介'},
                ],
            };
        },
        methods: {
            handleDrop(visible) {
                if (visible == true) {
                    this.isChange = true;
                }
                if (visible == false) {
                    this.isChange = false;
                }
            },
            handleSelect(index, indexPath) {
                if (!JSON.parse(localStorage.getItem("userObj"))) {
                    if (index === "/companyProfiles" || index === "/") {
                        this.$router.push(index);
                    } else {
                        this.$message({
                            message: "未登录，请先登录",
                            type: "error",
                            offset: 80,
                        });
                        this.router = false;
                        this.showPromotionDialog()
                    }
                } else {
                    this.$router.push(index);
                }
            },
            //调用PromotionDialog.vue里面的selectPromotion方法
            showPromotionDialog() {
                this.$refs.promotionDialog.selectPromotion()
            },
            toHome() {
                this.$router.push({name: 'home'})
            },
            logOut() {
                localStorage.clear();
                this.userObj = {
                    loginPhone: ''
                };
                this.$message({
                    type: 'success',
                    message: '退出成功',
                    offset: 80,
                });
                this.$router.push({name: 'home'})
            },
            handleClick(tab) {
                console.log(tab.index)
                if (tab.index == 1) {//二维码登录
                    this.getLoginImg();
                }
                if (tab.index == 0) {//短信验证码登录
                    console.log(777)
                    window.clearInterval(this.intervalId); // 清除定时器
                    this.intervalId = null
                }
            },
            loginDialog() {
                this.dialogVisible = true;
                this.verification = '';//登录验证码
                this.phone = '';//登录手机号
            },
            // handleSelect(key) {
            //   this.isShow = this.defaultActive != key;
            //   window.console.log(this.isShow);
            // },

            getLoginImg() {
                getLoginImg().then((res) => {
                    const base64String = 'data:image/png;base64,' + res.data.base64Str;
                    this.imageSrc = base64String;
                    this.sceneId = res.data.sceneId;
                    this.loading = false;
                    this.setIntervalScan();
                })
            },
            checkScan() {//查询扫描状态
                let _ = this;
                let params = {
                    sceneId: this.sceneId
                };
                checkScan(params).then((res) => {//1:未扫2：已扫未绑定ID3;3绑定成功
                    // if(res.msg ==2){
                    //
                    // }
                    console.log(res)
                    if (res.msg == 3) {
                        window.clearInterval(_.intervalId);
                        this.login();
                    }
                })
            },
            setIntervalScan() {
                // 定时器轮询后台接口
                this.intervalId = window.setInterval(() => {
                    this.checkScan();
                }, 1000);
            },
            login() {
                let params = {
                    sceneId: this.sceneId
                };
                loginScan(params).then((res) => {
                    if (res.code === 200) {
                        // let  jwt = require('jsonwebtoken');
                        let str = jwtDecode(res.data);
                        let userObj = JSON.stringify(str);
                        this.userObj = str;
                        localStorage.setItem('userObj', userObj);
                        localStorage.setItem('token', res.data);
                        this.$message({
                            type: 'success',
                            message: '登录成功',
                            offset: 80,
                        });
                        this.dialogVisible = false;
                    }
                })
            },
            open() {
                if (!this.phone) {
                    this.$message({
                        type: 'error',
                        message: '请输入手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.phoneReg.test(this.phone)) {
                    this.$message({
                        type: 'error',
                        message: '请输入正确手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.captcha) {
                    // 因为mounted的时候验证码的js还没加载，因此JumeiActionCaptcha实例必须在open的时候创建
                    this.captcha = new JumeiActionCaptcha(this.CAPTCHA_APP_ID, this.callback, {})
                }
                this.captcha.show()
            },
            callback(captchaRes) {
                // 属性如下：
                // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
                // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
                // bizState    Any       自定义透传参数。
                // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
                console.log('callback:', captchaRes)

                if (captchaRes.ret === 0) {
                    console.log('客户端验证成功')
                    // todo 此处需要调用接入者的服务端接口，完成验证，详见服务端接入说明
                    // ticket  randstr传给接口
                    let params = {
                        phoneNum: this.phone,
                        randstr: captchaRes.randstr,
                        ticket: captchaRes.ticket,
                    };
                    sendMobileCode(params).then((res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '发送成功',
                                offset: 80,
                            });
                            this.startCountdown(60); // 假设倒计时60秒
                            // this.captcha.hide();
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg,
                                offset: 80,
                            });
                        }
                    })
                } else {
                    console.log('验证失败')
                }
            },
            startCountdown(seconds) {
                this.countdown = seconds;
                this.intervalIdSendMobileCode = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown -= 1;
                    } else {
                        clearInterval(this.intervalIdSendMobileCode);
                    }
                }, 1000);
            },
            loginSendMobileCode() {
                if (!this.phone) {
                    this.$message({
                        type: 'error',
                        message: '请输入手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.phoneReg.test(this.phone)) {
                    this.$message({
                        type: 'error',
                        message: '请输入正确手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.verification) {
                    this.$message({
                        type: 'error',
                        message: '请输入验证码',
                        offset: 80,
                    });
                    return false
                }
                let params = {
                    phoneNum: this.phone,
                    code: this.verification
                };
                phoneLogin(params).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '登录成功',
                            offset: 80,
                        });
                        let str = jwtDecode(res.data);
                        let userObj = JSON.stringify(str);
                        this.userObj = str;
                        localStorage.setItem('userObj', userObj);
                        localStorage.setItem('token', res.data);
                        this.dialogVisible = false;
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg,
                            offset: 80,
                        });
                    }
                })
            },
            getList() {

                let params = {
                    username: 'admin',
                    password: '1234567',
                    KeyID: '04180601BB464135312D1069FF0B192C',
                    rnd: '317799',
                    returnEncData: 'E263DDD24B49005D5FB450A691273346FD78ED758FE7DA4957904F68C0B71B838A616AF57EE4F261AC774C4740529C5B9CE548ECDD7AC9854757CA84EF6E8A90',
                    macAddress: 's7meKVjaC4fwejp+TOY4PAeanYZWS+AXe7gFWvuJAPh841lK/kyZeOwdb5ll2A0mZxhZ7t23+/NaMDBv5wEQxxRCx8eUbed96GTw/kSHXv0vm6SGuBJ9MCBbG4Gsf9R36hXNXwPcxHtoiod/xhcs+IcH6eo8mv+lNf1TUZ7+btE='
                };
                login(params).then((res) => {
                    console.log(res)
                })
            },
            checkOverflow() {
                let  container = this.$el.querySelector('.menu-container');
                let  menuItems = this.$el.querySelectorAll('.el-menu-item');
                let totalWidth = 0;
                this.menuItems = this.menuItems?this.menuItems:this.menuItemsPc;
                this.menuItems.forEach((item, index) => {
                        totalWidth += menuItems[index].clientWidth;
                });
                this.showDropdown = totalWidth > container.clientWidth;
                let menu= this.showDropdown?this.menuItemsMobile:this.menuItemsPc;
                this.menuItems =menu;
                // console.log(totalWidth,container.clientWidth)
                // if(this.showDropdown){
                //     this.menuItems = [
                //         {index: '/', label: '首页'},
                //         {index: '/deliveryOrder', label: '下单发货'},
                //     ]
                // }else {
                //     this.menuItems = [
                //         {index: '/', label: '首页'},
                //         {index: '/deliveryOrder', label: '下单发货'},
                //         {index: '/myOrderList', label: '我的运单'},
                //         {index: '/selfHelpLoan', label: '自助放款'},
                //         {index: '/changeInformation', label: '修改信息'},
                //         {index: '/paymentReconciliation', label: '货款对账'},
                //         {index: '/companyProfiles', label: '企业简介'},
                //     ]
                // }
            }
        },
        mounted() {
            if(JSON.parse(localStorage.getItem('userObj'))){
                let currentTime = parseInt(new Date().getTime()/1000);
                // let currentTime = 9999999999;
                this.userObj = JSON.parse(localStorage.getItem('userObj'));
                if(this.userObj.exp < currentTime){
                    localStorage.clear();
                    this.userObj.loginPhone = '';
                    this.userObj.userName = '';
                    this.$message({
                        type: 'error',
                        message: '登录失效，请重新登录',
                        offset: 80,
                    });
                }
            }else {
                localStorage.clear();
                this.userObj.loginPhone = '';
                this.userObj.userName = '';
                this.$router.push('/')
            }
            // this.userObj = JSON.parse(localStorage.getItem('userObj')) ? JSON.parse(localStorage.getItem('userObj')) : {}
            // this.$nextTick(()=>{
            //     this.checkOverflow();
            //     window.addEventListener('resize', this.checkOverflow);
            // });

            // this.getLoginImg();
            // 动态引入验证码的js

            // let script = document.createElement('script');
            // script.type = 'text/javascript';
            // script.src = 'https://api-web.lianhdt.com/action-captcha/action-captcha.min.js';
            // let head = document.head || document.getElementsByTagName('head')[0];
            // head.appendChild(script)


            function isMobileOrTablet() {
                let userAgent = navigator.userAgent || navigator.vendor || window.opera;
                return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
                    && !/1237|6000f|i853|i855|i856|i857|i858|i860|i861|i862|i863|i864|i865|i939|j300|j500|j700|n300|n302|n303|n500|n502|n505|n700|n701|n710|nec-microwave|nec-sw|nec-vz|pg|playstation portable|pocket pc|pocket pc 2.0|pocket pc 2.10|pocket pc 2.20|pocket pc 2.30|pocket pc 2.50|pocket pc paint|sharp|sie-|softbank|sony ericsson|sonyericsson|sph-|symbian|symbianos|symbian v9|wap|windows ce|windows phone|xda|zte/i.test(userAgent);
            }

            if (isMobileOrTablet()) {
                this.menuItems = this.menuItemsMobile;
                this.showDropdown = true;
                console.log('H5（移动端）');
            } else {
                console.log('PC');
                this.menuItems = this.menuItemsPc;
                this.showDropdown = false;
            }






        },
        destroyed() {
            if (this.intervalId) {
                clearInterval(this.intervalId); // 清除定时器
                this.intervalId = null
            }
        },
        beforeDestroy() {
            // window.removeEventListener('resize', this.checkOverflow);
        },
    };
</script>

<style lang="scss">
    .el-menu.el-menu--horizontal {
        border-bottom: solid 0px #e6e6e6!important;
    }
    .el-tabs__item {
        width: 100%;
        padding: 0px !important;
        text-align: center;
        font-size: 18px !important;
        margin: 1vh 0;
    }

    .dialog {
        border: 1px solid #797979;
        border-radius: 10px;
        font-size: 28px;

        font-weight: normal;
        text-align: CENTER;
    }

    .el-dialog__header {
        padding: 0 !important;
        margin: 0 !important;
    }

    .el-dialog__body {
        padding: 0 !important;
        margin: 0 !important;
    }

    .el-menu-item {
        font-size: 16px !important;
        font-weight: normal;
        line-height: 16px;
        color: #FFFFFF !important;
        border-bottom-color: #3D64F9 !important;
    }

    .el-menu-item:hover { //鼠标扫过样式
        background-color: rgba(255, 255, 255, 0.2) !important;
        text-decoration: none;
        border-bottom-color: #FFFFFF !important;
    }

    .el-menu-item.is-active { //当前选中样式
        text-decoration: none;
        border: 0;
        background-color: rgba(255, 255, 255, 0.2) !important;
        text-decoration: none;
        border-bottom-color: #FFFFFF !important;
    }

    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        height: 100%;
    }

    .el-header {
        font-family: Microsoft YaHei, Microsoft YaHei-Normal;
        display: flex;
        background-image: url('./assets/img/Tab.png');
        width: 100%;

        .logo {
            width: 5%;
            margin: 1vh 15vw;

            /*img {*/
                /*width: 100%;*/
            /*}*/
        }
    }

    .el-main {
        padding: 0 !important;
        border-bottom: none;
    }

    .el-dropdown {
        color: white !important;

    }

    .el-dropdown-link {
        cursor: pointer;
        color: white;
        font-size: 16px;
    }

    .menu-container {
        width: 100%;
        overflow: hidden;
    }
    .custom-submenu {
        background-color: #f00; /* 将背景色设置为红色 */
    }
    .el-menu--horizontal{
        background: #0981f9;
    }
    .el-submenu__title{
        background: transparent!important;
    }
</style>
