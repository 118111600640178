import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let changjirouter = new Router({
  mode: 'history', // 使用history模式
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {//下单发货
      path: '/deliveryOrder',
      name: 'deliveryOrder',
      component: () => import('./views/DeliveryOrder.vue'),
      meta: {requireAuth:true}
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
    },
    {//订单详情
      path: '/orderInfo',
      name: 'orderInfo',
      component: () => import('./views/orderInfo.vue'),
      meta: {requireAuth:true}
    },
    {//待开单订单详情
      path: '/netOrderInfo',
      name: 'netOrderInfo',
      component: () => import('./views/netOrderInfo.vue'),
      meta: {requireAuth:true}
    },
    {
      path: '/newsdetails/:id',
      name: 'newsdetails',
      component: () => import('./views/NewsDetails.vue'),
      meta: {requireAuth:true}
    },
    {//我的运单
      path: '/myOrderList',
      name: 'myOrderList',
      component: () => import('./views/myOrderList.vue'),
      meta: {requireAuth:true}
    },
    {//自助放款
      path: '/selfHelpLoan',
      name: 'selfHelpLoan',
      component: () => import('./views/selfHelpLoan.vue'),
      meta: {requireAuth:true}
    },
    {//企业简介
      path: '/companyProfiles',
      name: 'companyProfiles',
      component: () => import('./views/companyProfiles')
    },
    {
      path: '/casedetails/:id',
      name: 'casedetails',
      component: () => import('./views/CaseDetails.vue')
    },
    {//修改信息
      path: '/changeInformation',
      name: 'changeInformation',
      component: () => import('./views/changeInformation.vue'),
      meta: {requireAuth:true}
    },
    {//货款对账
      path: '/paymentReconciliation',
      name: 'paymentReconciliation',
      component: () => import('./views/paymentReconciliation.vue'),
      meta: {requireAuth:true}
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      meta: {
        requireAuth: true
      },
      component: () => import('./views/Admin.vue'),
      children: [{
          path: '/admin/user',
          name: 'user',
          component: () => import('./views/Admin/User.vue')
        },
        {
          path: '/admin/news',
          name: 'new',
          component: () => import('./views/Admin/News.vue')
        },
        {
          path: '/admin/cases',
          name: 'cases',
          component: () => import('./views/Admin/Cases.vue')
        },
        {
          path: '/admin/team',
          name: 'team',
          component: () => import('./views/Admin/Team.vue')
        },
        {
          path: '/admin/course',
          name: 'course',
          component: () => import('./views/Admin/Course.vue')
        },
        {
          path: '/admin/enterprise',
          name: 'enterprise',
          component: () => import('./views/Admin/Enterprise.vue')
        },
        {
          path: '/admin/honor',
          name: 'honor',
          component: () => import('./views/Admin/Honor.vue')
        },
        {
          path: '/admin/dictionary',
          name: 'dictionary',
          component: () => import('./views/Admin/Dictionary.vue')
        },
        {
          path: '/admin/page',
          name: 'page',
          component: () => import('./views/Admin/Page.vue')
        }
      ]
    }
  ]
})

// 判断是否需要登录权限 以及是否登录
changjirouter.beforeEach((to, from, next) => {
  // 判断是否需要登录权限
  if (to.matched.some(res => res.meta.requireAuth)) {
    // 判断是否登录
    if (JSON.parse(localStorage.getItem("userObj"))) {
      next()
    } else {
      // 没登录则跳转到登录界面
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

export default changjirouter
