import request from "../utils/request";
export function login(params) {
    return request({
        url: "login/login",
        method: "post",
        params: params,
    });
}
export function getLoginImg(params) {
    return request({
        url: "wx/register/pc/createQrCode",
        method: "post",
        params: params,
    });
}
export function checkScan(params) {
    return request({
        url: "wx/register/pc/checkScan",
        method: "post",
        params: params,
    });
}
export function loginScan(params) {
    return request({
        url: "wx/pc/mini/login",
        method: "post",
        params: params,
    });
}
export function sendMobileCode(params) {
    return request({
        url: "wx/pc/sendMobileCode?",
        method: "post",
        params: params,
    });
}
export function phoneLogin(params) {
    return request({
        url: "wx/pc/phoneLogin?",
        method: "post",
        params: params,
    });
}
