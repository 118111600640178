<!--登录弹窗-->
<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        :width="dialogWidth"
        :show-close="showClo"
        style="padding-top: 150px"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane label="验证码登录" name="first" style="margin:20px;text-align: center;" >
          <el-input v-model="phone" placeholder="请输入手机号" style="width: 100%;margin:0 auto" @keyup.enter.native="$refs.verification.focus()"></el-input>
          <div style="display: flex;margin:10px 0;" >
            <el-input v-model="verification" placeholder="请输入验证码" style="margin-right: 10px;" ref="verification" @keyup.enter.native="loginSendMobileCode"></el-input>
            <el-button type="primary" @click="open()" :disabled="countdown > 0">  {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>
          </div>
          <el-button type="primary" style="width: 100%;margin-top: 40px" @click="loginSendMobileCode">立即登录</el-button>
        </el-tab-pane>

        <el-tab-pane label="微信登录" name="second" style="text-align: center;">
          <div style="margin:0 auto" v-loading="loading" :element-loading-text="text">
            <img v-lazy="imageSrc"  style="width: 200px;height: 200px;"/>
          </div>

          <div style="margin-bottom: 20px;">请打开微信扫一扫,快速登录</div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {checkScan, getLoginImg, login, loginScan, phoneLogin, sendMobileCode} from "@/api/homeApi";
import {jwtDecode} from "jwt-decode";

export default {
  data() {
    return {
      text:'',
      state:1,
      loading: true,
      verification:'',//登录验证码
      phone:'',//登录手机号
      showClo:false,
      activeName: 'first',
      dialogVisible: false,//登录弹窗
      router: true,
      defaultActive: "/",
      isShow: false,
      imageSrc: '',
      sceneId: '',
      intervalId: null,
      intervalIdSendMobileCode: null,//短信验证码倒计时
      countdown : 0,//短信验证码倒计时
      captcha: null,
      userObj:{
        loginPhone:''
      },
      dialogWidth:'30%',
    };
  },
  watch:{
    'dialogVisible':function(newVal, oldVal) {
      if(newVal == false){
        clearInterval(this.intervalId); // 清除定时器
        this.intervalId = null
      }
      if(newVal == true && this.state == 2){
        this.getLoginImg();
      }
    }
  },
  methods: {
    selectPromotion() {
      this.dialogVisible = true;
    },
    logOut(){
      localStorage.clear();
      this.userObj = {
        loginPhone:''
      };
      this.$message({
        type: 'success',
        message: '退出成功',
        offset: 80,
      });
      this.$router.push({ name: 'home' })
    },
    handleClick(tab) {
      // console.log(tab.index)
      if(tab.index == 1){//二维码登录
        this.getLoginImg();
        this.state = 2;
      }
      if(tab.index == 0){//短信验证码登录
        this.state = 1;
        window.clearInterval(this.intervalId); // 清除定时器
        this.intervalId = null
      }
    },
    loginDialog(){
      this.dialogVisible = true;
      this.verification='';//登录验证码
      this.phone='';//登录手机号
    },
    handleSelect(key) {
      this.isShow = this.defaultActive != key;
      window.console.log(this.isShow);
    },

    getLoginImg() {
      getLoginImg().then((res) => {
        const base64String = 'data:image/png;base64,' + res.data.base64Str;
        this.imageSrc = base64String;
        this.sceneId = res.data.sceneId;
        this.loading = false;
        this.setIntervalScan();
      })
    },
    checkScan() {//查询扫描状态
      let _ = this;
      let params = {
        sceneId: this.sceneId
      };
      checkScan(params).then((res) => {//1:未扫2：已扫未绑定ID3;3绑定成功
        if(res.msg == 2){
          this.loading = true;
          this.text = '扫码登录中';
        }
        if (res.msg == 3) {
          window.clearInterval(_.intervalId);
          this.login();
        }
      })
    },
    setIntervalScan() {
      // 定时器轮询后台接口
      this.intervalId = window.setInterval(() => {
        this.checkScan();
      }, 1000);
    },
    login() {
      let params = {
        sceneId: this.sceneId
      };
      loginScan(params).then((res) => {
        if (res.code === 200) {
          // let  jwt = require('jsonwebtoken');
          let str = jwtDecode(res.data);
          let userObj=JSON.stringify(str);
          this.userObj = str;
          localStorage.setItem('userObj',userObj);
          localStorage.setItem('token',res.data);
          this.$message({
            type: 'success',
            message: '登录成功',
            offset: 80,
          });
          this.dialogVisible = false;
          location.reload()
        }
      })
    },
    open() {
      if(!this.phone){
        this.$message({
          type: 'error',
          message: '请输入手机号',
          offset: 80,
        });
        return false
      }
      if (!this.phoneReg.test(this.phone)) {
        this.$message({
          type: 'error',
          message: '请输入正确手机号',
          offset: 80,
        });
        return false
      }
      if (!this.captcha) {
        // 因为mounted的时候验证码的js还没加载，因此JumeiActionCaptcha实例必须在open的时候创建
        this.captcha = new JumeiActionCaptcha(this.CAPTCHA_APP_ID, this.callback, {})
      }
      this.captcha.show()
    },
    callback(captchaRes) {
      // 属性如下：
      // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
      // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
      // bizState    Any       自定义透传参数。
      // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
      // console.log('callback:', captchaRes)

      if (captchaRes.ret === 0) {
        // console.log('客户端验证成功')
        // todo 此处需要调用接入者的服务端接口，完成验证，详见服务端接入说明
        // ticket  randstr传给接口
        let params = {
          phoneNum:this.phone,
          randstr:captchaRes.randstr,
          ticket:captchaRes.ticket,
        };
        sendMobileCode(params).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '发送成功',
              offset: 80,
            });
            this.startCountdown(60); // 假设倒计时60秒
            // this.captcha.hide();
          }else {
            this.$message({
              type: 'error',
              message: res.msg,
              offset: 80,
            });
          }
        })
      } else {
        console.log('验证失败')
      }
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      this.intervalIdSendMobileCode = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(this.intervalIdSendMobileCode);
        }
      }, 1000);
    },
    loginSendMobileCode() {
      if(!this.phone){
        this.$message({
          type: 'error',
          message: '请输入手机号',
          offset: 80,
        });
        return false
      }
      if (!this.phoneReg.test(this.phone)) {
        this.$message({
          type: 'error',
          message: '请输入正确手机号',
          offset: 80,
        });
        return false
      }
      if (!this.verification) {
        this.$message({
          type: 'error',
          message: '请输入验证码',
          offset: 80,
        });
        return false
      }
      let params = {
        phoneNum :this.phone,
        code:this.verification
      };
      phoneLogin(params).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '登录成功',
            offset: 80,
          });
          let str = jwtDecode(res.data);
          let userObj=JSON.stringify(str);
          this.userObj = str;
          localStorage.setItem('userObj',userObj);
          localStorage.setItem('token',res.data);
          this.dialogVisible = false;
          location.reload()
        }else {
          this.$message({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        }
      })
    },
    getList(){

      let params={
        username: 'admin',
        password: '1234567',
        KeyID: '04180601BB464135312D1069FF0B192C',
        rnd: '317799',
        returnEncData: 'E263DDD24B49005D5FB450A691273346FD78ED758FE7DA4957904F68C0B71B838A616AF57EE4F261AC774C4740529C5B9CE548ECDD7AC9854757CA84EF6E8A90',
        macAddress: 's7meKVjaC4fwejp+TOY4PAeanYZWS+AXe7gFWvuJAPh841lK/kyZeOwdb5ll2A0mZxhZ7t23+/NaMDBv5wEQxxRCx8eUbed96GTw/kSHXv0vm6SGuBJ9MCBbG4Gsf9R36hXNXwPcxHtoiod/xhcs+IcH6eo8mv+lNf1TUZ7+btE='
      };
      login(params).then((res) => {
        console.log(res)
      })
    },

  },
  mounted() {
    this.userObj = JSON.parse(localStorage.getItem('userObj'))?JSON.parse(localStorage.getItem('userObj')):{}
    // this.getLoginImg();
    // 动态引入验证码的js

    // let script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://api-web.lianhdt.com/action-captcha/action-captcha.min.js';
    // let head = document.head || document.getElementsByTagName('head')[0];
    // head.appendChild(script)
    // this.getDeliveryList()
    function isMobileOrTablet() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
              && !/1237|6000f|i853|i855|i856|i857|i858|i860|i861|i862|i863|i864|i865|i939|j300|j500|j700|n300|n302|n303|n500|n502|n505|n700|n701|n710|nec-microwave|nec-sw|nec-vz|pg|playstation portable|pocket pc|pocket pc 2.0|pocket pc 2.10|pocket pc 2.20|pocket pc 2.30|pocket pc 2.50|pocket pc paint|sharp|sie-|softbank|sony ericsson|sonyericsson|sph-|symbian|symbianos|symbian v9|wap|windows ce|windows phone|xda|zte/i.test(userAgent);
    }

    if (isMobileOrTablet()) {
      this.dialogWidth = '30%';
      console.log('H5（移动端）');
    } else {
      this.dialogWidth = '20%';
      console.log('PC');

    }

  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // 清除定时器
      this.intervalId = null
    }
  },
};
</script>

<style scoped>
/* 样式 */
</style>

