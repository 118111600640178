import axios from "axios";
// import {showMessage} from './statusMessage'
import { Message } from "element-ui";

const service = axios.create({
    // baseURL: 'http://8.152.162.160:18080/tms-cloud-miniprogram-service/'//测试地址
    baseURL: 'https://www.sycj.net/tms-cloud-miniprogram-service/'//正式
    //baseURL: 'http://192.168.1.202:18080/tms-cloud-miniprogram-service/'
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
service.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = config.contentType?config.contentType:"application/json;charset=utf-8";
        config.headers["authorization"] = localStorage.getItem('token')?localStorage.getItem('token'):'';
        // config.headers["AUTH_ID"] = JSON.parse(sessionStorage.getItem("AUTH_ID")); // 设置请求头
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// response 拦截器
// 可以在接口响应后统一处理结果
service.interceptors.response.use(
    (response) => {
        // console.log("-----------response---------------", response);
        let res = response.data;
        // if (res.exception) {
        //   Message.error(exception);
        //   return;
        // }
        // 如果是返回的文件
        if (response.config.responseType === "blob") {
            return res;
        }

        // 兼容服务端返回的字符串数据
        if (typeof res === "string") {
            res = res ? JSON.parse(res) : res;
        }
        return res;
    },
    (error) => {
        console.log(error.response.status)
        if(error.response.status){
            switch (error.response.status) {
                case 401:
                    Message.error("未登录，请先登录");
                    return false;
                case 503:
                    Message.error("网络异常,请稍后再试");
                    return false
            }
            return Promise.resolve(error.response);
        }
        // Message.error("网络异常,请稍后再试" + error);
        // return Promise.reject(error);
    }
);

export default service;
